@use "src/styles/typography";
@use "src/styles/buttons";

.rich_text_wrapper {
  position: relative;
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden;
  padding-bottom: 1px;
  visibility: hidden;
}

.loaded {
  visibility: visible;
}

.button {
  position: absolute !important;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  &.open {
    position: relative !important;
  }
}

.gradient {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4rem;
  background-image: linear-gradient(180deg, rgba(255, 0, 0, 0) 0%, rgba(255, 255, 255, 1) 40%);
}

.rich_text {
  @each $number in (1, 2, 3, 4, 5) {
    h#{$number} {
      @if $number <= 4 {
        @extend .header_4;
      } @else {
        @extend .header_#{$number};
      }

      margin-bottom: 0.875rem;
    }
  }
}
