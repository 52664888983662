@use "src/styles/buttons";
$animationDuration: 0.5s;

.wrapper {
  border-bottom: 1px solid var(--grey-border);
  padding: 2rem 1rem 1rem;
}

.button {
  @extend %reset-button;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .label {
    font-weight: 800;
    line-height: 1.5;
    text-align: left;
  }
  margin-bottom: 1rem;
}

.content {
  overflow: hidden;
  --max-height: none;
  transition:
    display $animationDuration,
    max-height $animationDuration,
    opacity $animationDuration;
  transition-behavior: allow-discrete;
  transition-timing-function: ease-in-out;
  display: none;
  opacity: 0;
  max-height: 0vh;
}

.is_open .content {
  display: block;
  opacity: 1;
  max-height: var(--max-height, none);

  @starting-style {
    max-height: 0vh;
    opacity: 0;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
