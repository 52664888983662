@use "src/styles/typography";
@use "src/styles/layout";
@use "src/styles/palette";

.container {
  @include layout.container("md");
}

.caption {
  @extend .caption_text;

  text-align: center;

  @media (max-width: layout.breakpoint(sm)) {
    text-align: left;
  }
}

.title {
  @extend .header_3;

  text-align: center;
  margin-top: 0.5rem;

  @media (max-width: layout.breakpoint(sm)) {
    text-align: left;
  }
}

.items {
  display: flex;
  column-gap: 1rem;
  row-gap: 3rem;

  @media (max-width: layout.breakpoint(sm)) {
    flex-direction: column;

    img {
      max-height: 280px;
    }
  }
}

.item {
  width: 100%;
}

.item_title {
  @extend .header_4;

  margin-top: 1.25rem;
  margin-bottom: 0.625rem;
}

.item_author {
  @extend .paragraph;

  color: palette.grey(darker);
}

.cta {
  display: inline-block;
}

.title_wrapper {
  margin-bottom: 4rem;
  @media (max-width: layout.breakpoint(sm)) {
    margin-bottom: 1.25rem;
  }
}

.subtitle {
  @extend .paragraph;

  text-align: center;
  margin-top: 0.5rem;
}
