@use "src/styles/palette";

.TextInput {
  width: 100%;

  input {
    font-family: 'AmsterdamSans', sans-serif;
    font-size: 1rem;
    width: 100%;
    border: 2px solid palette.brand('black', 'base');
    padding: 0.5rem 1rem;
    border-radius: var(--border-radius);
    color: palette.brand('black');

    &[type=date] {
      -webkit-appearance: none;
      background-color: palette.$white;
      min-height: 2.6rem;
    }
  }

  label {
    padding: 0.3rem 0;
  }
}

.has_error {
  input {
    border-color: palette.error('base');
  }
}