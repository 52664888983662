.day {
  display: flex;
  margin-bottom: .5rem;
}

.day_name {
  text-transform: capitalize;
}

.time {
  margin-left: auto;
}