@use "src/styles/layout";
@use "src/styles/palette";
@use "src/styles/typography";

.grid {
  @include layout.grid();
}

.column {
  @include layout.column();
}

.container {
  width: 100%;
}

.item_header {
  font-size: 1.375rem;
  line-height: 120%;
}

.intro {
  @extend .intro;

  margin-bottom: 1.5rem;
}

.quote {
  margin-top: 1.5rem;
}
