.background {
  position: absolute;
  overflow: hidden;
  opacity: 0;
  background: linear-gradient(var(--750-fresh-green), var(--750-warm-orange));
  width: 100%;
  height: 100%;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
    background: linear-gradient(
      140deg,
      var(--750-warm-orange),
      var(--750-fresh-green)
    );
  }
}

[data-theme="750"] {
  .background {
    opacity: 1;
  }
}
