@use "src/styles/layout";

.breadcrumbs {
  padding: 1.5rem 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  // set a min height to prevent layout shift while breadcrumbs are loading async
  min-height: 20px;

  @media (max-width: layout.breakpoint(md)) {
    padding: 0.75rem;
  }
}

.crumb {
  display: flex;
  align-items: center;

  svg {
    margin: 0 0.5rem;
    height: 0.75rem;
    width: auto;
  }

  &_dots {
    @extend .crumb;

    @media (max-width: layout.breakpoint(md)) {
      // The first crumb that has "_dots" displays the dots.
      &:not(:nth-child(3)) {
        display: none;
      }

      &:nth-of-type(3) {
        span,
        a {
          display: none;
        }

        &:after {
          content: "...";
          display: block;
        }
      }
    }
  }

  &_last {
    @extend .crumb;

    span {
      opacity: 0.6;
    }
  }

  &_home {
    line-height: 0;

    svg {
      height: 1rem;
      width: auto;
    }
  }
}
