@use "src/styles/typography";
@use "src/styles/cards";
@use "src/styles/palette";

.card_title {
  @extend .card_title;
  @extend .capitalize;
}

.card_body {
  composes: card_body;
}

.detail {
  margin-top: 1.25rem;
}

.detail_label {
  @extend .card_subtitle;
  font-size: 1rem;
  margin-bottom: 0.375rem;
}

.more_data_button {
  font-weight: 400;
  line-height: 1.1375;
  font-size: 0.875rem;

  svg {
    transition: transform 0.3s ease;
  }
  &:hover {
    svg {
      transform: translateY(3px);
    }
  }
}

.location {
  &_name {
    font-weight: 700;
    margin-bottom: 0.375rem;

    svg {
      width: 1rem;
      vertical-align: middle;
      margin-right: 0.75rem;
    }
  }

  &_address {
    margin-left: 1.75rem;
    line-height: 1.375;
  }
}

.play_dates {
  margin-bottom: 0.625rem;
  background-color: palette.grey("light");
  padding: 0.625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--border-radius);

  &_date {
    &:first-letter {
      text-transform: uppercase;
    }
  }
}

.soldout {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 1rem;
  color: palette.error("base");
}

.socials {
  display: flex;
  flex-direction: row;
  gap: 0.6875rem;
}

.tags {
  margin-bottom: 1.5rem;
}
