@use "src/styles/palette";

.bar {
  width: 100%;
  height: .125rem;
  background-color: palette.grey();
  margin-top: 2rem;
  overflow: hidden;
}

.progress_bar {
  height: .125rem;
  background-color: palette.grey(darker);
  position: relative;
}