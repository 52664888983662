@use "src/styles/buttons";
@use "src/styles/typography";
@use "src/styles/palette";

.video_responsive {
  overflow: hidden;
  position: relative;
  border-radius: var(--border-radius);
  aspect-ratio: 16/9;

  iframe {
    height: 100%;
    width: 100%;
    border: none;
  }
}

.video_thumb {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
}

.overlay_content {
  position: absolute;
  text-align: center;
}

.title {
  @extend .header_3;
  color: palette.$white;
}

.play_button {
  @extend %reset-button;
  margin: 1rem auto;
  background: palette.brand("black");
  color: palette.$white;
  padding: 0.625rem 1.875rem;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  font-weight: 700;
}

.thumbnail {
  display: block;
  width: 100%;
  height: auto;
}
