.button {
  svg {
    transition: transform 0.3s ease-in-out;
  }

  .clone {
    transform: translateX(2rem);
  }

  &:hover {
    // only animate the svg element, not the clone
    // and only when it has a sibling with the class .clone
    svg:not(.clone):has(~ .clone) {
      transform: translateX(-2rem);
    }
    .clone {
      transform: translateX(0);
    }
  }
}
