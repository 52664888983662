%reset-button {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 1rem;
  line-height: normal;
  color: inherit;

  // Fixes bug in Safari <= 14
  &:active {
    color: inherit;
  }
}