@use "src/styles/layout";
@use "src/styles/typography";
@use "src/styles/palette";

.overflow_setter {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.container {
  @include layout.container(xl);
  position: relative;
  width: 100%;
}

.hero {
  display: grid;
  min-height: 100vh;
  width: 100%;
  position: relative;
  padding: 2rem;
  grid-template-columns: 3fr 3fr 3fr;
  grid-template-areas:
    "title title image"
    "content content image"
    "buttons buttons image";
  grid-row-gap: 1.5rem;
  align-content: center;
  align-items: center;

  justify-items: left;

  @media (max-width: layout.breakpoint(md)) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "title"
      "image"
      "quiz_cta"
      "content"
      "prize_cta";
    justify-items: center;
    padding: 0rem 2rem;
  }
}

.content {
  grid-area: content;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  z-index: 1;
  color: palette.$white;
  max-width: 28rem;

  @media (max-width: layout.breakpoint(md)) {
    margin-top: 2.5rem;
    max-width: 100%;
  }
}

.title_wrapper {
  grid-area: title;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  color: palette.$white;
  z-index: 1;
  @media (max-width: layout.breakpoint(md)) {
    text-align: center;
    gap: 1rem;
  }
}

.title_pile {
  display: grid;
  > * {
    grid-area: 1/ 1;
  }
}

.super {
  @extend %quiz_overline;
}

.title {
  @extend %quiz_header_1;
  will-change: color;
  display: none;
  color: palette.$black;
  // large word-spacing value makes sure each word is always on a new line
  word-spacing: 100vw;

  font-size: clamp(8rem, 2rem + 11.1111vw, 14rem);

  @media (max-width: layout.breakpoint(md)) {
    font-size: clamp(3rem, -0.2rem + 13.3vw, 6.5rem);
  }
}

.introduction {
  @extend %quiz_header_4;
}

.description {
  @extend .intro;
}

.buttons {
  grid-area: buttons;
  display: flex;
  gap: 1rem;
}

.button_0 {
  grid-area: quiz_cta;
  z-index: 1;
  transition:
    transform 0.3s,
    background-color 0.3s;
  &:hover {
    transform: scale(1.15) rotate(3deg);
  }
}

.button_1 {
  grid-area: prize_cta;
  @media (max-width: layout.breakpoint(md)) {
    justify-self: flex-start;
  }

  svg {
    transition: transform 0.3s;
  }

  &:hover {
    text-decoration: underline;
    svg {
      transform: scale(1.8) rotate(-3deg);
    }
  }
}

.imageWrapper {
  display: grid;
  grid-area: image;
  align-items: center;
  scale: 2;

  @media (max-width: layout.breakpoint(md)) {
    scale: 1.6;
  }
}

.image {
  grid-area: 1 / 1;
  opacity: 0;
  img {
    max-width: 100%;
    height: auto;
  }

  @media (max-width: layout.breakpoint(md)) {
    width: 100%;
    position: relative;
  }
}
