$black: #000;
$matte-black: #4d4d4d;
$white: #fff;
$success: #00b050;

$brand-colors: (
  "primary": (
    "base": var(--primary-base),
    "contrast": var(--primary-contrast),
    "light": var(--primary-light),
    "negative": var(--primary-negative),
  ),
  "secondary": (
    "base": var(--secondary-base),
    "contrast": var(--secondary-contrast),
    "light": var(--secondary-light),
    "negative": var(--secondary-negative),
  ),
  "black": (
    "base": var(--black),
    "contrast": var(--white),
    "light": var(--grey-support-light),
    "negative": var(--primary-negative),
  ),
  "white": (
    "base": var(--white),
    "contrast": var(--black),
    "light": var(--grey-base),
    "negative": var(--primary-negative),
  ),
);

$error-colors: (
  "base": var(--error-base),
  "contrast": var(--error-contrast),
  "light": var(--error-light),
  "dark": var(--error-dark),
);

$grey: (
  "light": var(--grey-light),
  "base": var(--grey-base),
  "dark": var(--grey-dark),
  "darker": var(--grey-darker),
  "border": var(--grey-border),
  "support": var(--grey-support),
  "support-light": var(--grey-support-light),
);

$warning: (
  "yellow": var(--warning-yellow),
  "yellow-light": var(--warning-yellow-light),
);

@function brand($key: primary, $variant: base) {
  $color: map-get($brand-colors, $key);
  @return map-get($color, $variant);
}

@function grey($variant: base) {
  @return map-get($grey, $variant);
}

@function error($variant: base) {
  @return map-get($error-colors, $variant);
}
