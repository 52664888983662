@use "src/styles/layout";
@use "src/styles/palette";
@use "src/styles/layers";

%arrow {
  --arrow-icon-size: 3.125rem;

  z-index: layers.$floatingButton;
  position: absolute;
  top: calc(50% - (var(--arrow-icon-size) / 2));
  width: var(--arrow-icon-size);
  height: var(--arrow-icon-size);
  background-color: palette.grey(light);
  border: 2px solid palette.grey(border);
  color: black;
  transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    background-color: palette.grey(light);
    border-color: black;
  }

  &:disabled {
    opacity: 0.5;
  }

  svg {
    height: 1.125rem !important;
  }

  /* smartphones, touchscreens */
  @media (hover: none) and (pointer: coarse) {
    display: none;
  }
}

.arrow_left {
  @extend %arrow;
  left: 0.5rem;
}

.arrow_right {
  @extend %arrow;
  right: 0.5rem;
}
