@use "src/styles/palette";

$transition: 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

.Checkbox {
  label {
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    position: relative;
    line-height: 1.4rem;

    > span {
      margin-left: 0.5rem;
    }
  }

  input {
    position: relative;
    appearance: none;
    background-color: palette.$white;
    margin: 0;

    font: inherit;
    color: currentColor;
    width: 1.15em;
    min-width: 1.15em;
    height: 1.15em;
    min-height: 1.15em;
    border: 0.15em solid palette.brand("black", "base");
    border-radius: 0.15em;
    transform: translateY(0.075em);
    display: grid;
    place-content: center;
    transition: all $transition;
    transform-origin: center;

    &:checked {
      background-color: palette.brand("black", "base");
    }

    &:not(:disabled) {
      cursor: pointer;
    }
  }

  &_checkmarkIcon {
    pointer-events: none;
    color: palette.$white;
    display: none;
    position: absolute;
    padding-left: 2px;
  }

  input:checked ~ &_checkmarkIcon {
    display: block;
  }
}

.has_error {
  input {
    border-color: palette.error("base");

    &:checked {
      background-color: palette.error("base");
    }
  }
}
