@use "src/styles/typography";
@use "src/styles/palette";

.promotion {
  border-top: 1px solid palette.grey(border);
  margin-top: 1.25rem;
  padding-top: 1.25rem;
}

.promotion_title {
  @extend .header_5;
  margin: 0;
}

.promotion_description {
  font-size: 1rem;
  line-height: 137%;
  font-weight: 400;
  margin-bottom: 0.625rem;
}

.promotion_dates {
  color: palette.brand("black", light);
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0.625rem;
}

.promotion_order_button {
  margin-bottom: 0.625rem;
}

.promotion_info_link {
  font-size: 0.875rem;
  display: block;
}
