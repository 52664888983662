@use "src/styles/palette";

.container {
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

.bar {
  transform: translateY(calc(100% + 2rem));
  transition: transform 0.3s ease-in-out;
  &.visible {
    transform: translateY(calc(0% - 2rem));
  }
}
