@use "src/styles/typography";
@use "src/styles/layout";
@use "src/styles/palette";

.header_3 {
  @extend .header_3;
}

.block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.items {
  width: 100%;
  margin: 3rem 0;
}

.cta {
  display: inline-block;
  margin-left: auto !important;

  @media (max-width: layout.breakpoint(md)) {
    order: 3;
    margin-left: 0 !important;
  }
}

.title_wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.body {
  @extend .paragraph;
}
