@use "src/styles/layout";

.contact {
  margin-bottom: .5rem;
  color: inherit;
  text-decoration: none;

  svg {
    width: 1rem;
    margin-right: .75rem;
  }

  @media (max-width: layout.breakpoint(md)) {
    margin-bottom: 1rem;
  }
}