@use "sass:meta";
@use "palette";
@use "breakpoints";

$breakpoints: (
  "xs": breakpoints.$breakpoint-xs,
  "sm": breakpoints.$breakpoint-sm,
  "md": breakpoints.$breakpoint-md,
  "lg": breakpoints.$breakpoint-lg,
  "xl": breakpoints.$breakpoint-xl
);

@function breakpoint($key) {
  @return #{map-get($breakpoints, $key)}px;
}

@mixin media_query($key) {
  @media screen and (min-width: #{map-get($breakpoints, $key)}px) {
    @content;
  }
}

// specifcally for when you want to target only BELOW the smallest breakpoint
@mixin below_media_query($key) {
  @media screen and (max-width: #{map-get($breakpoints, $key)}px) {
    @content;
  }
}

@mixin between_media_query($down, $up) {
  @media screen and (min-width: #{map-get($breakpoints, $down)}px) and (max-width: #{map-get($breakpoints, $up)}px) {
    @content;
  }
}

@each $key, $value in $breakpoints {
  .container_#{$key} {
    max-width: #{$value}px;
    margin: 0 auto;
  }
}

@mixin container($size: "xl") {
  max-width: breakpoint($size);
  margin: 0 auto;
}

@mixin grid($gap: 1) {
  display: flex;
  flex-wrap: wrap;
  // TODO: division in sass is deprecated: https://sass-lang.com/documentation/breaking-changes/slash-div
  padding: 0 #{$gap / 2}rem;

  & > * {
    padding: 0 #{$gap / 2}rem;
  }
}

@mixin grid-gap($args...) {
  @each $key, $value in meta.keywords($args) {
    @if map-has-key($breakpoints, $key) {
      @media (min-width: breakpoint($key)) {
        gap: calc(100% / 12) * $value;
      }
    }
  }
}

@mixin column($args...) {
  width: 100%;

  @each $key, $value in meta.keywords($args) {
    @if map-has-key($breakpoints, $key) {
      @media (min-width: breakpoint($key)) {
        width: calc(100% / 12) * $value;
      }
    }
  }
}

@mixin colum-offset($args...) {
  @each $key, $value in meta.keywords($args) {
    @if map-has-key($breakpoints, $key) {
      @media (min-width: breakpoint($key)) {
        margin-left: calc(100% / 12) * $value;
      }
    }
  }
}

.spacing {
  width: 100%;
  height: 1px;
  background-color: transparent;
  margin: 2.5rem auto;

  @media (max-width: breakpoint(md)) {
    margin: 2rem 0;
  }
}

.divider {
  @extend .spacing;

  background-color: palette.grey();
}
