@use "src/styles/palette";

.tooltip_wrapper {
  position: relative;
}

.tooltip {
  position: absolute;
  min-width: 9rem;
  text-align: center;
  left: 50%;
  background: var(--grey-light);
  color: palette.brand("black");
  border-radius: 0.5rem;
  padding: 0.625rem;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  line-height: 150%;

  &.show {
    opacity: 1;
  }
}
