@use "src/styles/layout";
@use "src/styles/typography";
@use "src/styles/palette";

.container {
  position: relative;
  display: grid;
  column-gap: 2rem;
  row-gap: 0.5rem;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 
    "image title"
    "image description";
  justify-content: center;
  align-items: center;

  @media (max-width: layout.breakpoint(md)) {
    grid-template-columns: 1fr;
    grid-template-areas: 
      "title"
      "description"
      "image";
  }
}

.image_wrapper {
  grid-area: image;
}

.text {
  transition: color 0.3s ease-in-out;
  color: var(--profile-color-support);
}

.title {
  grid-area: title;
  @extend .header_3; 
  align-self: flex-end;
}

.description {
  grid-area: description;
  @extend .body__large;
  align-self: flex-start;
}