@use "src/styles/typography";
@use "src/styles/layout";

.card {
  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;
  grid-column: span 1 / span 1;

  @media (min-width: layout.breakpoint(sm)) {
    grid-column: span 2 / span 2;
  }

  .background {
    opacity: 1;
  }

  .container {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    padding: 1.5rem;

    @media (min-width: layout.breakpoint(sm)) {
      padding: 3.5rem 2rem;
    }

    .content {
      display: flex;
      flex-direction: column;
      color: var(--black);

      .title {
        @extend .header_3;
        max-width: 264px;
      }

      .description {
        max-width: 364px;
      }

      .link {
        width: fit-content;
      }
    }
  }
}
