@use "src/styles/layout";
@use "src/styles/typography";
@use "src/styles/palette";

.heading {
  text-align: center;
}

.products {
  @include layout.grid($gap: 0);
  margin-bottom: 2rem;
  padding: 0 0.5rem;
  justify-content: center;
  list-style-type: none;

  @media (max-width: layout.breakpoint("lg")) {
    flex-wrap: wrap;
  }

  &_narrow {
    @media (min-width: layout.breakpoint("sm")) {
      flex-wrap: nowrap;
    }
  }
}

.product_wrapper {
  position: relative;
  width: 100%;
  max-width: 385px;
  padding-right: 1rem;
  margin-bottom: 1rem;

  &:last-child {
    padding-right: 0;
  }

  @include layout.column($xs: 12, $sm: 6, $md: 4, $lg: calc(12 / 5));

  &_narrow {
    max-width: 350px;
    width: 100%;

    @media (max-width: layout.breakpoint("sm")) {
      padding-right: 0;
      max-width: 100%;
    }
  }
}

.pricing_footer {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  gap: 1.5rem;

  border: 1px solid palette.grey("border");
  border-radius: var(--border-radius);
  background-color: palette.grey("light");

  @media (max-width: layout.breakpoint("sm")) {
    flex-direction: column;
  }
}

.checkout_buttonwrapper {
  display: flex;
  align-items: center;
  gap: 1.5rem;

  @media (max-width: layout.breakpoint("sm")) {
    flex-direction: column;
    align-items: flex-start;

    button {
      width: 100%;
    }
  }
}
