@use "src/styles/typography";
@use "src/styles/palette";
@use "src/styles/animations";

$spinnerSizeLarge: 96px;
$spinnerSizeMedium: 64px;
$spinnerSizeSmall: 32px;

.SpinningLoader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  h4 {
    @extend .header_4;
  }
}

.loader {
  position: relative;
  overflow: hidden;

  span {
    color: palette.brand('secondary');
  }

  &__primary {
    span {
      color: palette.brand('primary');
    }
  }

  &__black {
    span {
      color: palette.brand('black');
    }
  }

  &__white {
    span {
      color: palette.$white;
    }
  }

  &__large {
    width: $spinnerSizeLarge;
    height: $spinnerSizeLarge;
    @include animations.spinning-loader(calc($spinnerSizeLarge / 2), 5px, currentColor, palette.grey('border'));
  }

  &__medium {
    width: $spinnerSizeMedium;
    height: $spinnerSizeMedium;
    @include animations.spinning-loader(calc($spinnerSizeMedium / 2), 3px, currentColor, palette.grey('border'));
  }

  &__small {
    width: $spinnerSizeSmall;
    height: $spinnerSizeSmall;
    @include animations.spinning-loader(calc($spinnerSizeSmall / 2), 2px, currentColor, palette.grey('border'));
  }
}