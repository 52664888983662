@use "src/styles/layout";
@use "src/styles/typography";
@use "src/styles/palette";

.section {
  position: relative;
  width: 100%;
  margin: 5rem 0;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  scroll-margin-top: 4rem;

  @media (max-width: layout.breakpoint(md)) {
    gap: 1.5rem;
  }

  [data-animation-id="slideIn"] {
    opacity: 0;
  }
}

.title {
  @extend %quiz_header_3;
  transition: color 0.3s ease-in-out;
  color: var(--profile-color-support);
}

.list {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}
