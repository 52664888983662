@use "src/styles/layout";
@use "src/styles/palette";
@use "src/styles/layers";

$arrowSize: 3.125rem;
// this is the height of the card image to calculate the position of the arrows
// as they need to align with the center of the image
$cardImageHeight: 184px;

@property --left-mask-size {
  syntax: '<length>';
  initial-value: 0px;
  inherits: false;
}

@property --right-mask-size {
  syntax: '<length>';
  initial-value: 0px;
  inherits: false;
}

.ItemRow {
  position: relative;
}

.scrollContainer {
  position: relative;
  mask-image: linear-gradient(to right, transparent 0, black var(--left-mask-size, 0), black calc(100% - var(--right-mask-size, 0)), transparent 100%);
  --left-mask-size: 0px;
  --right-mask-size: 0px;
  transition: --left-mask-size 0.25s ease-in-out, --right-mask-size 0.25s ease-in-out;

  @media (max-width: layout.breakpoint(md)) {
    mask-image: none;
  }
}

.row {
  display: flex;
  column-gap: 1rem;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: layout.breakpoint(md)) {
    justify-content: initial;
  }

  > * {
    scroll-snap-align: start;
    width: 100%;
    max-width: 24rem;
    min-width: calc(30% - 2rem);

    @media (max-width: layout.breakpoint(lg)) {
      min-width: 35%;
      width: auto;
    }

    @media (max-width: layout.breakpoint(md)) {
      min-width: 45%;
      width: auto;
    }

    @media (max-width: layout.breakpoint(sm)) {
      min-width: 80%;
      width: auto;
    }
  }

  &_overflow {
    @extend .row;

    justify-content: initial;
  }

  &_inline {
    > * {
      min-width: auto;
      white-space: nowrap;
    }
  }
}

.arrow_position {
  --arrow-icon-size: 3.125rem;
  top: calc(($cardImageHeight / 2) - (var(--arrow-icon-size) / 2));
}