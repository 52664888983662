@use "src/styles/layout";

.container {
  gap: 0.5rem;
  display: flex;
  align-items: center;

  p {
    margin: 0;
  }
}

.share_count {
  display: inline-block;
}
