@use "src/styles/typography";
@use "src/styles/layout";
@use "src/styles/palette";

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  height: 100%;

  padding: 1.5rem;
  border: 1px solid palette.grey('border');
  border-radius: var(--border-radius);
  cursor: default;

  @media (max-width: layout.breakpoint(sm)) {
    align-items: flex-start;
  }

  h3,
  h3 span {
    @extend .header_3;
    text-align: center;
    display: inline-block;
  }

  @media (min-width: layout.breakpoint(sm)) {
    h2 span {
      display: block;
      font-size: 1rem;
      font-weight: normal;
    }
  }
}

.card_header {
  text-align: center;
}

.subtitle {
  color: palette.$matte-black;
}

.card_footer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media (max-width: layout.breakpoint(sm)) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
}

.card_pricing {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: layout.breakpoint(sm)) {
    align-items: flex-start;
  }
}

.card_price {
  font-size: 1.5rem;
}

.card_pricebefore {
  font-size: 1rem;
  text-decoration: line-through;
}