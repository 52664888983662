@use "src/styles/typography";

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: .5rem;
}

.tag {
  @extend .tag_text;
}