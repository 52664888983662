@use "palette";
@use "typography";
@use "layout";

.card {
  background-color: palette.$white;
  border-radius: .25rem;
  box-shadow: 0 .375rem 2rem rgba(0, 0, 0, 0.1);

  @media (max-width: layout.breakpoint(md)) {
    border-radius: 0;
  }

  &_body {
    padding: 1.875rem
  }

  &_title {
    @extend .header_4;
    &--small {
      @extend .header_5;
    }
  }

  &_subtitle {
    @extend %headers;
  }

  &__flat {
    box-shadow: none;
  }
}