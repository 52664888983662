@use "src/styles/palette";
@use "src/styles/typography";
@use "src/styles/layout";

.container {
  margin: 2.5rem 0;
}

.heading {
  @extend .header_4;

  margin-bottom: 1.25rem;
}

.tag_label {
  display: flex;
  flex-direction: column;
  background-color: palette.grey(light);
  padding: 1.25rem;
  gap: 1.25rem;
  border-radius: 0.25rem;

  @media (min-width: layout.breakpoint(sm)) {
    flex-direction: row;
  }

  &_title {
    @extend .header_4;

    margin-bottom: 0.625rem;
  }

  &_description {
    @extend .paragraph;

    @media (max-width: layout.breakpoint(sm)) {
      font-size: 1rem;
    }

    a {
      &:hover {
        text-decoration: none;
      }
    }
  }

  &_link {
    display: block;
    margin-top: 0.75rem;
    color: palette.brand();

    &:hover {
      text-decoration: none;
    }
  }

  &_image {
    min-width: 8.25rem;
  }
}

.theme {
  .heading {
    text-transform: uppercase;
  }
  .tag_label {
    background: var(--750-gradient-02);
    color: #fff;

    &_title {
      text-transform: uppercase;
    }

    &_description {
      color: #fff;

      a {
        color: #fff;
      }
    }

    &_link {
      color: #fff;
    }
  }
}
