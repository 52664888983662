@use "src/styles/layout";

.grid {
  display: grid;
  grid-column-gap: 1rem;
  grid-row-gap: var(--grid-row-gap, 4rem);
}

.ad_card {
  display: grid;
  grid-column-end: 0;
}

@for $number from 3 through 4 {
  .cols_#{$number} {
    > .ad_card {
      min-height: 400px;
      grid-column-end: span 2;
    }

    > * {
      position: relative;
    }

    @include layout.below_media_query(sm) {
      grid-template-columns: repeat(1, 1fr);

      > .ad_card {
        grid-column-end: span 1;
      }
    }

    @include layout.between_media_query(sm, md) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include layout.between_media_query(md, lg) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include layout.media_query(lg) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
