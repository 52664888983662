.container {
  container-type: inline-size;
  container-name: share-event-card;
}

.inner_container {
  padding: 1rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: auto 1fr;
  border-radius: 0.5rem;
  background: url("/images/750/background-cross-orange.svg"), linear-gradient(86deg, #e8091f 3.63%, #fa9d18 96.37%);
  background-repeat: no-repeat, no-repeat;

  background-size: contain, cover;
  background-position:
    center right,
    center;

  color: var(--white);
}

@container share-event-card (max-width: 20rem) {
  .inner_container {
    .icon {
      display: none;
    }
  }
}

@container share-event-card (min-width: 30rem) {
  .inner_container {
    background-size: cover, cover;
  }
}

.text {
  text-wrap: balance;
}

.heading {
  text-transform: uppercase;
  margin: 0;
}

.share_buttons {
  margin-top: 1rem;
}
