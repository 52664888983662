@use "src/styles/palette";
@use "src/styles/buttons";

%icon_button {
  @extend %reset-button;

  overflow: clip;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 2.25rem;
  transition: background-color 0.25s linear;
  display: grid;
  place-items: center;
  > * {
    grid-area: 1 / 1;
  }
}

@mixin iconButton($key, $variant: base) {
  background-color: palette.brand($key, $variant);
  color: palette.brand($key, contrast);

  &:focus-visible {
    outline: 3px solid palette.brand(secondary);
  }

  &:hover {
    background-color: palette.brand($key, light);
  }

  &:disabled {
    background-color: palette.brand($key, light);
    opacity: 0.5;
  }

  .outline {
    border-color: palette.brand($key, contrast);
  }
}

.icon_button {
  @extend %icon_button;

  &.outline {
    border: 2px solid transparent;
  }

  &.primary {
    @include iconButton(primary);
  }

  &.secondary {
    @include iconButton(secondary);
  }

  &.default {
    @include iconButton("black");
  }

  &.tertiary {
    @include iconButton("white");
  }
}
