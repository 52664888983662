@use "palette";
@use "layout";

@mixin set-font-face($family, $weight, $style, $filename) {
  @font-face {
    font-family: $family;
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
    src: url("/fonts/eot/" + $filename + ".eot");
    src:
      url("/fonts/eot/" + $filename + ".eot#iefix") format("embedded-opentype"),
      url("/fonts/woff2/" + $filename + ".woff2") format("woff2"),
      url("/fonts/woff/" + $filename + ".woff") format("woff");
  }
}

@mixin set-font-face-woff2($family, $weight, $style, $filename) {
  @font-face {
    font-family: $family;
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
    src: url("/fonts/woff2/" + $filename + ".woff2") format("woff2");
  }
}

%base {
  font-family: "AmsterdamSans", sans-serif;
  margin: 0;
}

%headers {
  @extend %base;

  font-weight: 700;
}

%quiz-headers {
  font-family: "FormulaCondensed", sans-serif;
  margin: 0;
  font-weight: 700;
}

.header_1 {
  @extend %headers;

  font-size: 4.5rem;
  line-height: 1.2;

  @media (max-width: layout.breakpoint(md)) {
    font-size: 2rem;
    line-height: 1.2;
  }

  &__heavy {
    @extend .header_1;

    font-weight: 800;
  }
}

.header_2 {
  @extend %headers;

  font-size: 3rem;
  line-height: 120%;

  @media (max-width: layout.breakpoint(md)) {
    font-size: 1.75rem;
    line-height: 120%;
  }

  &__heavy {
    @extend .header_2;

    font-weight: 800;
  }
}

.header_3 {
  @extend %headers;

  font-size: 1.75rem;
  line-height: 120%;
}

.header_4 {
  @extend %headers;

  font-size: 1.375rem;
  line-height: 120%;
}

.header_5 {
  @extend %headers;

  font-size: 1.125rem;
  line-height: 1.5;
}

.header_6 {
  @extend %headers;

  font-size: 1rem;
  line-height: 133%;
}

.header_2_unresponsive {
  @extend %headers;

  font-size: 3rem;
  line-height: 120%;

  &__heavy {
    @extend .header_2_unresponsive;

    font-weight: 800;
  }
}

.paragraph {
  @extend %base;

  font-size: 1.125rem;
  line-height: 150%;
  color: palette.$matte-black;

  &__heavy {
    @extend .paragraph;

    font-weight: 700;
  }
}

.ul {
  @extend %base;

  font-size: 1.125rem;
  line-height: 150%;
  color: palette.$matte-black;
}

.body {
  @extend %base;

  font-size: 1rem;
  line-height: 150%;
  &__bold {
    @extend .body;
    font-weight: 800;
  }

  &__large {
    font-size: 1.125rem;
    &__bold {
      @extend .body__large;
      font-weight: 700;
    }
  }
}

.intro {
  @extend %base;

  font-size: 1.375rem;
  line-height: 140%;
}

.tag_text {
  @extend %base;

  font-weight: 700;
  font-size: 0.875rem;
}

.caption_text {
  @extend %base;

  font-size: 0.875rem;
  color: palette.$matte-black;
}

.capitalize {
  &::first-letter {
    text-transform: capitalize;
  }
}

.break_word {
  word-wrap: break-word;
  hyphens: auto;
}

.logoSubtext {
  @extend %base;
  font-size: 0.75rem;
  opacity: 0.6;
}

// QUIZ TYPOGRAPHY
%quiz_header_1 {
  @extend %quiz-headers;
  font-size: 14rem;
  line-height: 0.85;
  font-weight: 700;
  text-transform: uppercase;

  @media (max-width: layout.breakpoint(md)) {
    font-size: 4.5rem;
  }
}

%quiz_header_2 {
  @extend %quiz-headers;
  font-size: 6.5rem;
  line-height: 0.85;
  font-weight: 700;
  text-transform: uppercase;

  @media (max-width: layout.breakpoint(md)) {
    font-size: 3.5rem;
    line-height: 0.9;
  }
}

%quiz_header_3 {
  @extend %quiz-headers;
  font-size: 3.5rem;
  line-height: 0.9;
  font-weight: 700;
  text-transform: uppercase;
}

%quiz_header_4 {
  @extend %headers;
  font-size: 2.5rem;
  line-height: 0.95;
  font-weight: 700;
}

%quiz_header_5 {
  @extend %headers;
  font-size: 1.5rem;
  line-height: 0.95;
  font-weight: 700;
}

%quiz_header_6 {
  @extend %headers;
  font-size: 1rem;
  line-height: 0.95;
  font-weight: 700;
}

%quiz_overline {
  @extend %quiz-headers;
  font-size: 3rem;
  line-height: 0.8;
  font-weight: 700;
  text-transform: uppercase;

  @media (max-width: layout.breakpoint(md)) {
    font-size: 2rem;
    line-height: 1;
  }
}
