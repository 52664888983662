@use "~styles/_palette.scss";

.quantity_picker {
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;

  &__expanded {
    column-gap: 1rem;
    grid-template-columns: 36px 1fr 36px;
  }

  >span {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 60px;
    padding: 0 1rem;
    border: 2px solid palette.brand("black");
    border-radius: 20px;
  }
}

.icon_buttonwrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}