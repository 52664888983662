@use "src/styles/palette";
@use "src/styles/typography";

.feedfactory_container {
  overflow: hidden;
  width: 100%;
  perspective: 1px;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;

  img {
    object-fit: cover;
    width: inherit;
    height: inherit;
  }
}

.prepr_container {
  display: flex;
  overflow: hidden;
  border-radius: 0.25rem;
  width: 100%;

  img {
    object-fit: cover;
    width: 100%;
  }
}

.author {
  @extend .caption_text;

  margin-top: 1rem;
  border-left: 1px solid palette.grey(dark);
  color: palette.grey(darker);
  padding-left: 0.25rem;
}
