.button {
  display: block;
  margin-top: .375rem;
  color: rgba(0, 0, 0, 0.8);
  text-decoration: none;
  font-size: .875rem;

  svg {
    width: 1rem;
    vertical-align: middle;
    margin-right: .75rem;
    color: black;
  }
}