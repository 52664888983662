@use "src/styles/palette";

@keyframes spin {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

@mixin spinning-loader($radius: 8px, $borderWidth: 4px, $color: currentColor, $trackColor: transparent, $rotationTime: 0.5s) {
  &::after {
    content: "";
    position: absolute;
    width: calc($radius * 2);
    height: calc($radius * 2);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: $borderWidth solid $color;
    border-top-color: $trackColor;
    border-left-color: $trackColor;
    border-radius: 50%;
    animation: spin $rotationTime linear infinite;
  }
}

$collapse: 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;